.payEmi-wrapper .form_control {
  height: 2.5rem;
  width: 100%;
  flex: 1 1 0%;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.payEmi-wrapper .form_group {
  /* display: flex;
  align-items: center;
  gap: 1rem; */
}

.payEmi-wrapper .form_group_grid {
  display: grid;
  gap: 1rem;
}

.payEmi-wrapper #demoPaymentPage {
  max-height: 480px;
  overflow-y: auto;
}

.payEmi-wrapper #demoPaymentPage::-webkit-scrollbar {
  width: 20px;
}

.payEmi-wrapper #demoPaymentPage::-webkit-scrollbar-track {
  background-color: transparent;
}

.payEmi-wrapper #demoPaymentPage::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.payEmi-wrapper #demoPaymentPage::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.payEmi-wrapper #loanAccDetailsvalidateForm {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
}

.payEmi-wrapper {
  background: url("../images//PayEmi/PayEmiBanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
