.qtpdfBtn {
  display: flex;
  gap: 0.875rem;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(12, 12, 12, 0.1),
    0 0.125rem 0.4rem -0.0625rem rgba(112, 12, 12, 0.05);
  color: #232323;
  font-weight: 600;
  text-transform: capitalize !important;
  text-align: left;
}
.qtpdfBtn:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(12, 12, 12, 0.3),
    0 0.125rem 0.4rem -0.0625rem rgba(112, 12, 12, 0.05);
  color: #7ab700;
}
.pdf-icon {
  color: red;
  font-size: 1.4rem;
  width: 10%;
  margin-right: 0 !important;
}
.qtpdfBtn:focus {
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(12, 12, 12, 0.1),
    0 0.125rem 0.4rem -0.0625rem rgba(112, 12, 12, 0.03) !important;
}

.child-border-reset > :nth-child(2n) {
  border-right: 0;
}

@media (max-width: 575.98px) {
  .qtpdfBtn {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .qtpdfBtn svg {
    width: 100%;
  }

  .qtpdfBtn span {
    font-size: 0.8rem;
  }
}
