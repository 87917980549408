/* font family */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

/* root sheet */
:root {
  --primary-color: #8cc90d;
  --secondary-color: #366adb;
  --accent-color: #e74c3c;
  --background-color: #f5f5f5;
  --text-color: #131022;
  --border-color: #e5e7eb;
  --gray-color: #585c7b;
  --font-family: "Manrope", sans-serif;
  --dark-green: #569501;
}

@keyframes elementor-animation-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

body {
  font-family: var(--font-family);
}

:focus {
  outline: 0;
  box-shadow: none !important;
}

select:focus,
input:focus,
textarea:focus {
  border: 1px solid #dddddd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}
p {
  color: var(--gray-color);
  font-size: 1rem;
  font-weight: normal;
}

.inner-content ul li {
  color: var(--gray-color);
  font-size: 1rem;
  font-weight: normal;
}

.readmMoreBtn:hover {
  animation-name: elementor-animation-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

.icon-hover-fill {
  transition: fill 0.3s ease;
}

.icon-hover-fill:hover svg {
  fill: var(--secondary-color); /* Replace with the desired hover color */
}

.customShadow {
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.1),
    0 6px 9px -5px rgba(0, 0, 0, 0.19);
}

.customShadow:hover {
  box-shadow: 0 4px 13px -1px rgba(0, 0, 0, 0.1),
    0 8px 9px -3px rgba(0, 0, 0, 0.19);
}

input[type="text"] {
  background-color: #fff;
  border: 1px solid #e6ecf4;
  height: 40px;
  width: 100%;
  padding: 0 0.875rem;
  box-shadow: 0px 0px 1px 0px #00000033;
}

@media (min-width: 1024px) and (max-width: 1700px) {
  /* .direction-rtl {
    padding: 0 18rem;
  }

  .direction-ltr {
    padding: 0 18rem;
  } */
}

.custBorder {
  position: relative;
}

.custBorder::after {
  content: "";
  position: absolute;
  top: 0;
  right: -8px;
  width: 1px;
  height: 100%;
  background-color: #e3e3e3;
}

.submenusBox a {
  font-weight: 600;
}

.accordion-content {
  box-sizing: border-box;
}

/* .custBorder:nth-child(2n)::after {
  width: 0;
} */

/* Define the ribbon text styles */
/* .ribbon {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  padding: 0px 30px 4px 12px;
  display: inline-block;
  position: relative;
  background: radial-gradient(0.2em 50% at left, #000a, #0000) border-box,
    #f00 padding-box;
  width: fit-content;
  clip-path: polygon(
    100% 0,
    0 0,
    0 100%,
    100% 100%,
    100% calc(100% - 0.25em),
    calc(100% - 0.8em) 50%,
    100% 0.25em
  );
  border-block: 0.5em solid #0000;
  line-height: 1.8;
  margin: 0;
}

.ribbon span {
  font-size: 15px;
  font-weight: bold;
  color: #ffe000;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  letter-spacing: 1px;
  background: linear-gradient(45deg, #ff6b6b, #ff3f3f);
  -webkit-background-clip: text;
  background-clip: text;
  animation: blink 1.5s step-start infinite;
  white-space: nowrap;
} */

/* @keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --s: 1.8em; /* the ribbon size */
  --d: 0.8em; /* the depth */
  --c: 0.8em; /* the cutout part */

  padding: 0 calc(var(--s) + 0.5em) var(--d);
  line-height: 1.8;
  background: conic-gradient(
        at left var(--s) bottom var(--d),
        #0000 25%,
        #0008 0 37.5%,
        #0004 0
      )
      0 /50% 100% no-repeat,
    conic-gradient(
        at right var(--s) bottom var(--d),
        #0004 62.5%,
        #0008 0 75%,
        #0000 0
      )
      100%/50% 100% no-repeat;
  clip-path: polygon(
    0 var(--d),
    var(--s) var(--d),
    var(--s) 0,
    calc(100% - var(--s)) 0,
    calc(100% - var(--s)) var(--d),
    100% var(--d),
    calc(100% - var(--c)) calc(50% + var(--d) / 2),
    100% 100%,
    calc(100% - var(--s) - var(--d)) 100%,
    calc(100% - var(--s) - var(--d)) calc(100% - var(--d)),
    calc(var(--s) + var(--d)) calc(100% - var(--d)),
    calc(var(--s) + var(--d)) 100%,
    0 100%,
    var(--c) calc(50% + var(--d) / 2)
  );
  background-color: #cc333f; /* the main color */
  width: fit-content;
  padding: 0px 70px 26px 70px;
}

.ribbon span {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.8);
  letter-spacing: 1px;
  background: linear-gradient(45deg, #ff6b6b, #ff3f3f);
  -webkit-background-clip: text;
  background-clip: text;
  animation: blink 1.5s step-start infinite;
  white-space: nowrap;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  /* header .mobile-ribbon {
    visibility: hidden;
    display: none;
  } */
  .ribbon_desk {
    display: block;
  }
  .mobile-ribbon {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 1023.98px) {
  .mobile-ribbon {
    display: block;
  }

  .ribbon_desk {
    display: none;
  }
}
